*, *:before, *:after {
    box-sizing: border-box;
}

html, body, button {
    font-smoothing: antialiased;
}

body {
    font-size: var(--fontBase);
    font-weight: 400; /* to thicken it a bit, we need to remove font-smoothing */
    line-height: 1.6250em; /* 26px */
    overflow-y: scroll;
}

main {
    margin: 0 auto;
    padding: 16px;

    .mimic-tablet-port & {
        max-width: 768px;
    }

    .mimic-tablet-land & {
        max-width: 1024px;
    }

    .mimic-mobile-port & {
        max-width: 320px;
    }

    .mimic-mobile-land & {
        max-width: 480px;
    }
}

hr {
    border-bottom: 1px solid var(--colorBorder);
    border-left: none;
    border-right: none;
    border-top: none;
    margin: var(--lineHeight) 0;
}

img {
    margin: 0;
    max-width: 100%;
}

.gm-style img {
    max-width: none;
}

pre {
    background: var(--colorGrayBackground);
    padding: 13px;
}

.container {
    position: relative;
}

.relative {
    position: relative;
}

.promote-layer {
    /*
    This may promote the layer to a composited layer.
    Replace with will-change when available
    #perfmatters
    */
    backface-visibility: hidden;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.img-left {
    float: left;
    margin: 0 0 5px;
    @media (--viewport-medium-up) {
        margin-right: 15px;
        float: left;
    }
}

.img-right {
    margin: 0 0 5px;
    @media (--viewport-medium-up) {
        margin-left: 15px;
        float: right;
    }
}

.img-left-styled {
    @extend .img-left;
    border: 1px solid var(--colorBorderLight);
    padding: 5px;
}

.img-right-styled {
    @extend .img-right;
    border: 1px solid var(--colorBorderLight);
    padding: 5px;
}

.no-margin {
    margin: 0;
}

.hide {
    display: none !important;
    visibility: hidden;
}

@media print {
    .hide-print {
        display: none !important;
        visibility: hidden;
    }
}

.invisible {
    visibility: hidden;
}

.antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.pika-table {
    margin: 0;

    td, th {
        border: none;
    }

    abbr {
        border: none;
    }
}

.tether-element {
    display: none;
    position: absolute;
    &.tether-open {
        display: block;
    }
}