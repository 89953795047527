@import "components/_base.css";

html, body {
    height: 100%;
    width: 100%;
}

html {
    box-sizing: border-box;
}

body {
    background: #ecf0f1;
    color: #2c3e50;
    font-family: "Roboto Condensed", Helvetica, sans-serif;
    padding: 3em;
}

a {
    transition: color 0.1s linear;
    color: #3498db;
    text-decoration: none;
}

a:hover {
    color: #e74c3c;
}

.container {
    margin: 0 auto;
    max-width: 970px;
    position: relative;
}

.content-wrap {
    text-align: center;
}

.links {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.8em;
    line-height: 2.2;
    padding: 1.61em 5em;
}

.links a {
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    margin: 0 0 1em;
}

.fancybox-overlay {
    z-index: 999998;
}

.fancybox-opened {
    z-index: 999999;
}

@media (--viewport-small) {
    .block-on-small {
        display: block;
    }
}

*, *:before, *:after {
    box-sizing: inherit;
}

.clearfix:before, .cf:before,
.clearfix:after, .cf:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.clearfix:after, .cf:after {
    clear: both;
}

@import "font-awesome.css";