
h1, h2, h3, h4, h5, h6 {
    text-rendering: optimizeLegibility; /* Fix the character spacing for headings */
}

/* Use padding instead of margin */
h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}

@media (--viewport-medium-up) {
    h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
        padding-top: 0;
    }
}

small {
    font-size: var(--fontSmall);
    line-height: 2em; /* 26px */
    padding-top: 2em;
    padding-bottom: 0;
}

h4 {
    font-size: var(--fontMedium);
    font-weight: 300;
    line-height: 1.3000em; /* 26px */
    padding-top: 1.3000em;
    padding-bottom: 0;
}

h3 {
    font-family: var(--fontHeader);
    font-size: var(--fontLarge);
    font-weight: 300;
    line-height: 1.0000em; /* 26px */
    padding-top: 1.0000em;
    padding-bottom: 0;
}

h2 {
    font-family: var(--fontHeader);
    font-size: var(--fontXLarge);
    font-weight: 300;
    line-height: 1.2381em; /* 52px */
    padding-top: 0.6190em;
    padding-bottom: 0;
}

h1 {
    font-family: var(--fontHeader);
    font-size: var(--fontXXLarge);
    font-weight: 300;
    line-height: 1.1471em; /* 78px */
    padding-top: 0.3824em;
    padding-bottom: 0;
}

.huge-type {
    font-family: var(--fontHeader);
    font-size: var(--fontHuge);
    font-weight: 300;
    line-height: 1.19em; /* 130px */
    padding-top: 0.2364em;
    padding-bottom: 0;
}

li > p {
    padding-top: 0;
}

p + p {
    padding-top: 1.6250em; /* 26px */
}