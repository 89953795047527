[class*="block-grid-"] {
    display: block;
    padding: 0;
    margin: 0 -0.625rem; }

[class*="block-grid-"]:before, [class*="block-grid-"]:after {
    content: " ";
    display: table; }

[class*="block-grid-"]:after {
    clear: both; }

[class*="block-grid-"] > li {
    display: block;
    float: left;
    height: auto;
    padding: 0 0.625rem 1.25rem; }

@media only screen {
    .small-block-grid-1 > li {
        list-style: none;
        width: 100%; }

    .small-block-grid-1 > li:nth-of-type(1n) {
        clear: none; }

    .small-block-grid-1 > li:nth-of-type(1n+1) {
        clear: both; }

    .small-block-grid-2 > li {
        list-style: none;
        width: 50%; }

    .small-block-grid-2 > li:nth-of-type(1n) {
        clear: none; }

    .small-block-grid-2 > li:nth-of-type(2n+1) {
        clear: both; }

    .small-block-grid-3 > li {
        list-style: none;
        width: 33.33333%; }

    .small-block-grid-3 > li:nth-of-type(1n) {
        clear: none; }

    .small-block-grid-3 > li:nth-of-type(3n+1) {
        clear: both; }

    .small-block-grid-4 > li {
        list-style: none;
        width: 25%; }

    .small-block-grid-4 > li:nth-of-type(1n) {
        clear: none; }

    .small-block-grid-4 > li:nth-of-type(4n+1) {
        clear: both; }

    .small-block-grid-5 > li {
        list-style: none;
        width: 20%; }

    .small-block-grid-5 > li:nth-of-type(1n) {
        clear: none; }

    .small-block-grid-5 > li:nth-of-type(5n+1) {
        clear: both; }

    .small-block-grid-6 > li {
        list-style: none;
        width: 16.66667%; }

    .small-block-grid-6 > li:nth-of-type(1n) {
        clear: none; }

    .small-block-grid-6 > li:nth-of-type(6n+1) {
        clear: both; }

    .small-block-grid-7 > li {
        list-style: none;
        width: 14.28571%; }

    .small-block-grid-7 > li:nth-of-type(1n) {
        clear: none; }

    .small-block-grid-7 > li:nth-of-type(7n+1) {
        clear: both; }

    .small-block-grid-8 > li {
        list-style: none;
        width: 12.5%; }

    .small-block-grid-8 > li:nth-of-type(1n) {
        clear: none; }

    .small-block-grid-8 > li:nth-of-type(8n+1) {
        clear: both; }

    .small-block-grid-9 > li {
        list-style: none;
        width: 11.11111%; }

    .small-block-grid-9 > li:nth-of-type(1n) {
        clear: none; }

    .small-block-grid-9 > li:nth-of-type(9n+1) {
        clear: both; }

    .small-block-grid-10 > li {
        list-style: none;
        width: 10%; }

    .small-block-grid-10 > li:nth-of-type(1n) {
        clear: none; }

    .small-block-grid-10 > li:nth-of-type(10n+1) {
        clear: both; }

    .small-block-grid-11 > li {
        list-style: none;
        width: 9.09091%; }

    .small-block-grid-11 > li:nth-of-type(1n) {
        clear: none; }

    .small-block-grid-11 > li:nth-of-type(11n+1) {
        clear: both; }

    .small-block-grid-12 > li {
        list-style: none;
        width: 8.33333%; }

    .small-block-grid-12 > li:nth-of-type(1n) {
        clear: none; }

    .small-block-grid-12 > li:nth-of-type(12n+1) {
        clear: both; }
}

@media (--viewport-medium-up) {
    .medium-block-grid-1 > li {
        list-style: none;
        width: 100%; }

    .medium-block-grid-1 > li:nth-of-type(1n) {
        clear: none; }

    .medium-block-grid-1 > li:nth-of-type(1n+1) {
        clear: both; }

    .medium-block-grid-2 > li {
        list-style: none;
        width: 50%; }

    .medium-block-grid-2 > li:nth-of-type(1n) {
        clear: none; }

    .medium-block-grid-2 > li:nth-of-type(2n+1) {
        clear: both; }

    .medium-block-grid-3 > li {
        list-style: none;
        width: 33.33333%; }

    .medium-block-grid-3 > li:nth-of-type(1n) {
        clear: none; }

    .medium-block-grid-3 > li:nth-of-type(3n+1) {
        clear: both; }

    .medium-block-grid-4 > li {
        list-style: none;
        width: 25%; }

    .medium-block-grid-4 > li:nth-of-type(1n) {
        clear: none; }

    .medium-block-grid-4 > li:nth-of-type(4n+1) {
        clear: both; }

    .medium-block-grid-5 > li {
        list-style: none;
        width: 20%; }

    .medium-block-grid-5 > li:nth-of-type(1n) {
        clear: none; }

    .medium-block-grid-5 > li:nth-of-type(5n+1) {
        clear: both; }

    .medium-block-grid-6 > li {
        list-style: none;
        width: 16.66667%; }

    .medium-block-grid-6 > li:nth-of-type(1n) {
        clear: none; }

    .medium-block-grid-6 > li:nth-of-type(6n+1) {
        clear: both; }

    .medium-block-grid-7 > li {
        list-style: none;
        width: 14.28571%; }

    .medium-block-grid-7 > li:nth-of-type(1n) {
        clear: none; }

    .medium-block-grid-7 > li:nth-of-type(7n+1) {
        clear: both; }

    .medium-block-grid-8 > li {
        list-style: none;
        width: 12.5%; }

    .medium-block-grid-8 > li:nth-of-type(1n) {
        clear: none; }

    .medium-block-grid-8 > li:nth-of-type(8n+1) {
        clear: both; }

    .medium-block-grid-9 > li {
        list-style: none;
        width: 11.11111%; }

    .medium-block-grid-9 > li:nth-of-type(1n) {
        clear: none; }

    .medium-block-grid-9 > li:nth-of-type(9n+1) {
        clear: both; }

    .medium-block-grid-10 > li {
        list-style: none;
        width: 10%; }

    .medium-block-grid-10 > li:nth-of-type(1n) {
        clear: none; }

    .medium-block-grid-10 > li:nth-of-type(10n+1) {
        clear: both; }

    .medium-block-grid-11 > li {
        list-style: none;
        width: 9.09091%; }

    .medium-block-grid-11 > li:nth-of-type(1n) {
        clear: none; }

    .medium-block-grid-11 > li:nth-of-type(11n+1) {
        clear: both; }

    .medium-block-grid-12 > li {
        list-style: none;
        width: 8.33333%; }

    .medium-block-grid-12 > li:nth-of-type(1n) {
        clear: none; }

    .medium-block-grid-12 > li:nth-of-type(12n+1) {
        clear: both; }
}

@media (--viewport-large-up) {
    .large-block-grid-1 > li {
        list-style: none;
        width: 100%; }

    .large-block-grid-1 > li:nth-of-type(1n) {
        clear: none; }

    .large-block-grid-1 > li:nth-of-type(1n+1) {
        clear: both; }

    .large-block-grid-2 > li {
        list-style: none;
        width: 50%; }

    .large-block-grid-2 > li:nth-of-type(1n) {
        clear: none; }

    .large-block-grid-2 > li:nth-of-type(2n+1) {
        clear: both; }

    .large-block-grid-3 > li {
        list-style: none;
        width: 33.33333%; }

    .large-block-grid-3 > li:nth-of-type(1n) {
        clear: none; }

    .large-block-grid-3 > li:nth-of-type(3n+1) {
        clear: both; }

    .large-block-grid-4 > li {
        list-style: none;
        width: 25%; }

    .large-block-grid-4 > li:nth-of-type(1n) {
        clear: none; }

    .large-block-grid-4 > li:nth-of-type(4n+1) {
        clear: both; }

    .large-block-grid-5 > li {
        list-style: none;
        width: 20%; }

    .large-block-grid-5 > li:nth-of-type(1n) {
        clear: none; }

    .large-block-grid-5 > li:nth-of-type(5n+1) {
        clear: both; }

    .large-block-grid-6 > li {
        list-style: none;
        width: 16.66667%; }

    .large-block-grid-6 > li:nth-of-type(1n) {
        clear: none; }

    .large-block-grid-6 > li:nth-of-type(6n+1) {
        clear: both; }

    .large-block-grid-7 > li {
        list-style: none;
        width: 14.28571%; }

    .large-block-grid-7 > li:nth-of-type(1n) {
        clear: none; }

    .large-block-grid-7 > li:nth-of-type(7n+1) {
        clear: both; }

    .large-block-grid-8 > li {
        list-style: none;
        width: 12.5%; }

    .large-block-grid-8 > li:nth-of-type(1n) {
        clear: none; }

    .large-block-grid-8 > li:nth-of-type(8n+1) {
        clear: both; }

    .large-block-grid-9 > li {
        list-style: none;
        width: 11.11111%; }

    .large-block-grid-9 > li:nth-of-type(1n) {
        clear: none; }

    .large-block-grid-9 > li:nth-of-type(9n+1) {
        clear: both; }

    .large-block-grid-10 > li {
        list-style: none;
        width: 10%; }

    .large-block-grid-10 > li:nth-of-type(1n) {
        clear: none; }

    .large-block-grid-10 > li:nth-of-type(10n+1) {
        clear: both; }

    .large-block-grid-11 > li {
        list-style: none;
        width: 9.09091%; }

    .large-block-grid-11 > li:nth-of-type(1n) {
        clear: none; }

    .large-block-grid-11 > li:nth-of-type(11n+1) {
        clear: both; }

    .large-block-grid-12 > li {
        list-style: none;
        width: 8.33333%; }

    .large-block-grid-12 > li:nth-of-type(1n) {
        clear: none; }

    .large-block-grid-12 > li:nth-of-type(12n+1) {
        clear: both; }
}